@import "../../global.scss";

.certificates {
    background-color: #fff;
    // display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    h1 {
        padding: 0px 10px;
        text-align: center;
        font-size: 50px;

        @include mobile {
            font-size: 20px;
        }
    }

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotateY(180deg);
        }

        &.right {
            right: 100px;
        }
    }

    .slider {
        position: absolute;
        width: 400%;
        margin: 0;
        left: 0;
        animation: 20s slider infinite;

        .container {
            width: 100%;
        }

        figure img {
            width: 25%;
            height: 650px;
            float: left;
        }

        @keyframes slider {
            0% {
                left: 0;
            }
            20% {
                left: 0;
            }
            25% {
                left: -100%;
            }
            45% {
                left: -100%;
            }
            50% {
                left: -200%;
            }
            70% {
                left: -200%;
            }
            75% {
                left: -200%;
            }
            95% {
                left: -200%;
            }
            100% {
                left: -300%;
            }
        }
    }
}
