.app {
    height: 100vh;
    position: absolute;

    .sections {
        position: relative;
        top: 70px;
        width: 100%;
        height: calc(100vh - 50px);
        background-color: lightsalmon;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        &::-webkit-scrollbar {
            display: none;
        }

        > * {
            width: 100vw;
            height: calc(100vh - 50px);
            scroll-snap-align: end;
        }
    }
}
