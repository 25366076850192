@import "../../global.scss";

.intro {
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    .left {
        flex: 0.5;
        overflow: hidden;

        .imgContainer {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            border-radius: 50%;
            object-fit: cover;

            @include mobile {
                align-items: flex-start;
            }

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }

            @media screen and (max-width: 1500px) {
                .imgContainer {
                    flex: 0.4;
                }
            }

            @media screen and (max-width: 768px) {
                .imgContainer {
                    flex: 0.4;
                }
            }
        }
    }

    .right {
        position: relative;
        flex: 0.5;

        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 0;
                align-items: center;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include mobile {
                    font-size: 40px;
                }
            }
            h2 {
                font-size: 35px;
            }
            h3 {
                font-size: 30px;

                @include mobile {
                    font-size: 20px;
                }

                span {
                    font-size: inherit;
                    color: red;
                }

                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }

            a {
                position: absolute; // whenever there is a position absolute, parent element should be relative
                bottom: 50px;
                left: 40%;

                img {
                    width: 30px;
                    animation: arrowBlink 2s infinite;
                }

                @keyframes arrowBlink {
                    100% {
                        opacity: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 1500px) {
            .wrapper {
                height: 50%;
            }
        }

        @media screen and (max-width: 768px) {
            .wrapper {
                height: 50%;
            }
        }
    }
}
